import React from 'react';
import Laptop1 from '../images/laptop-1.svg';
import Laptop2 from '../images/laptop-2.svg';

export default function Example() {
  return (
    <div className="relative pt-8 pb-32 overflow-hidden">
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <Laptop1
                className="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                alt="Customer profile user interface"
              />
            </div>
          </div>
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-200">
                  Scale your cash collection
                </h2>
                <p className="mt-4 text-lg text-gray-300">
                  Tresorbase helps you scale and automate your accounts
                  receivable operations. Create, import and send invoices to
                  your customers in 10+ languages. Send automated reminders and
                  get a better visibility on your cash-flow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-200">
                  Achieve 80% reduction of manual operations.
                </h2>
                <p className="mt-4 text-lg text-gray-300">
                  Tresorbase reduces the amount of manual operations, like
                  updating spreadsheets or manual entry. Once your bank accounts
                  and accounting system are connected you we automatically
                  reconcile your invoices.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <Laptop2
                className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
