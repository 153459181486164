import React from 'react';
import {
  DocumentDuplicateIcon,
  CurrencyEuroIcon,
  ShieldCheckIcon,
  PresentationChartBarIcon,
  SwitchHorizontalIcon,
  FingerPrintIcon,
  DocumentTextIcon,
  CubeTransparentIcon,
} from '@heroicons/react/outline';
import Hero from '../components/hero';
import Feature from '../components/feature';
import Feature3 from '../components/feature3';
import CTA from '../components/cta';
import Layout from '../components/layout';
import Seo from '../components/seo';

const features = [
  {
    name: 'Bulk import & operations',
    description:
      'You can import hundreds of invoices through our APIs or CSV files, and send them to your customers or generate payments in just one click.',
    icon: DocumentDuplicateIcon,
  },
  {
    name: 'VAT Compliant',
    description:
      "Multiple Tax and VAT rates are supported. And your customers' VAT-ID is checked automatically.",
    icon: CurrencyEuroIcon,
  },
  {
    name: ' Privacy and security first',
    description:
      'Our platform is hosted in the European Union and we comply with all the privacy and security regulations.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'No more manual reconciliation',
    description:
      'We automatically reconcile the majority of your transactions, and we also offer a simple interface that will speed-up your reconciliation.',
    icon: DocumentTextIcon,
  },
  {
    name: 'API first',
    description:
      'Build new solutions and custom workflows with our API. You can integrate our API with your ERP or CRM and skip manual imports.',
    icon: CubeTransparentIcon,
  },
  {
    name: 'A fully auditable system ',
    description:
      'Every action inside Tresorbase is tracked with our audit log system. So at any moment you can check when a change was made.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Reporting',
    description:
      'Monitor your cashflow using our platform and track your receivables with advanced filters, bulk actions, and quick views.',
    icon: PresentationChartBarIcon,
  },
  {
    name: 'Easy exports and integrations',
    description:
      'Export your data to CSV or connect your system to our APIs. Your entire finance process can be built on top of it.',
    icon: SwitchHorizontalIcon,
  },
];

function IndexPage({ location }) {
  return (
    <Layout>
      <Seo
        title="Accounts Receivable Automation for B2B companies"
        path={location.pathname}
      />
      <Hero
        title={
          <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-200 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
            <span className="block">Receivable Automation </span>
            <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-purple-tb-400 bg-purple-tb-400 via-orange-tb-400 sm:pb-5">
              for B2B companies
            </span>
          </h1>
        }
        description="Put getting paid on autopilot. Cash-flow forecast and automated dunning in a single platform your team will enjoy use."
        cta
      />
      <Feature />
      <Feature3
        title="Getting paid: done."
        description="Let your team focus on high value activities while Tresorbase takes care of the rest. Send invoices, track payments. Automate reminders and proactively improve your cash collection."
        features={features}
      />
      <CTA />
    </Layout>
  );
}

export default IndexPage;
